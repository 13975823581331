import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";
import CssBaseline from "@mui/material/CssBaseline";

import { useSelector } from "react-redux";
import Index from "../pages";
import Aos from "aos";

export default function Router() {
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));

  useEffect(() => {
    Aos.refresh();
  }, [ui.isDarkMode]);

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
