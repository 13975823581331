import React, { useEffect, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Box, CircularProgress } from "@mui/material";
import Aos from "aos";

const Header = lazy(() => import("../component/Header"));
const LandingPage = lazy(() => import("../component/LandingPage"));
const Footer = lazy(() => import("../component/Footer"));

const style = {
  root: {
    backgroundColor: (theme) => theme.palette.background.default,
    overflow: "hidden",
  },
};

export default function Index() {
  useEffect(() => {
    Aos.refresh();
  }, []);

  return (
    <Box sx={style.root}>
      <Helmet>
        <title>itsjnathan</title>
        <meta
          name="description"
          content="I am a passionate programmer that loves to code. I build code-efficient website that will ..."
        />
      </Helmet>

      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={100} />
          </Box>
        }
      >
        <Header />
        <LandingPage />
        <Footer />
      </Suspense>
    </Box>
  );
}
